import React, { useCallback, useState } from 'react';
import CallToAction from '../../molecules/CallToAction/CallToAction';

const Report = ({ data, onRestart }) => {
  const [animatedAmount, setAnimatedAmount] = useState(0);
  const [animationDone, setAnimationDone] = useState(false);
  const rawAnnualRevenue = parseInt(
    data.addedAnnualRevenue.replace(/\$|,/g, ''),
    10
  );
  // Allow animation to run for 2 seconds at 60fps
  const animationDelta = Math.floor(rawAnnualRevenue / 120);

  const animateAmount = useCallback(
    max => {
      setTimeout(() => {
        setAnimatedAmount(prevState => {
          if (prevState + animationDelta < max) {
            return prevState + animationDelta;
          }

          setAnimationDone(true);

          return max;
        });
      }, 1);
    },
    [animationDelta]
  );

  if (!animationDone) {
    animateAmount(rawAnnualRevenue);
  }

  return (
    <div className={`report-wrapper${animationDone ? ' show-body' : ''}`}>
      <section className={`hero${animationDone ? ' raise' : ''}`}>
        <p className="kicker">
          Estimated annual increased revenue with Shopmonkey
        </p>
        <p className={`amount${animationDone ? ' pop' : ''}`}>
          ${animatedAmount.toLocaleString('en-US')}
        </p>
      </section>
      <section className="result-cards">
        <div className="report-card">
          <p className="loss-term">Weekly</p>
          <p className="value">{data.addedWeeklyRevenue}</p>
        </div>
        <div className="report-card">
          <p className="loss-term">Monthly</p>
          <p className="value">{data.addedMonthlyRevenue}</p>
        </div>
        <div className="report-card">
          <p className="loss-term">Annually</p>
          <p className="value">{data.addedAnnualRevenue}</p>
        </div>
      </section>
      <section>
        <div className="section-header">How? Here are the nuts and bolts:</div>
        <ul className="result-breakdown">
          <li>
            <div className="label">
              Reduce customer no-shows with automated reminders (+3%)
            </div>
            <div className="value">{data.reducedNoShowsIncrease}/week</div>
          </li>
          <li>
            <div className="label">
              Upsell repair services with digital inspections (+5%)
            </div>
            <div className="value">{data.digitalInspectionsIncrease}/week</div>
          </li>
          <li>
            <div className="label">
              Reduce time spent writing estimates (20% time saved)
            </div>
            <div className="value">{data.reducedTimeIncrease}/week</div>
          </li>
          <li>
            <div className="label">
              Reduce parts waste by tracking purchase orders/cores (+2%)
            </div>
            <div className="value">{data.reducedPartsWasteIncrease}/week</div>
          </li>
        </ul>
      </section>
      <section>
        <div className="section-header">
          And these are conservative estimates!
        </div>
        <div className="boosted-stats">
          <div className="header">
            The average shop boosts its revenue by 40% in their first year of
            using Shopmonkey.
            <br />
            <strong>Here’s your shop’s ROI with a 40% boost:</strong>
          </div>
          <div className="amount">+ {data.roiWithBoost}</div>
          <div className="footer">
            What would you do with an extra {data.roiWithBoost} per year?
          </div>
        </div>
      </section>
      <div className="restart-container">
        <CallToAction
          className="restart"
          customHandleClick={onRestart}
          size="sm"
          value="Restart Calculator"
          variant="tertiary"
        />
      </div>
    </div>
  );
};

export default Report;
